.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
              0 8px 10px 1px rgba(0, 0, 0, 0.14),
              0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.example-box:last-child {
  border: none;
}

.example-list.cdk-drop-list-dragging .example-box:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.overview{
  &__group{
    display: flex;
    flex-wrap: wrap;
  }

  &__analytics-group{
    display: flex;
    flex-flow: row wrap;
  }

  &__drawer-toggle{
    margin: 0 10px;
    display: flex;
    span{
      font-size: 14px;
      opacity: 0.8;
      padding-right: 15px;
      margin-right: 10px;
      position: relative;
      cursor: pointer;
      &:after {
        position: absolute;
        right: 0;
        top: 1.8px;
        font: normal normal normal 10px/1 FontAwesome;
        color: inherit;
        content: "\f133";
      }
    }

    button{
      background-color: $overview-component-header-background-color;
      border: none;
      color: $body-text-color;
      transition: opacity 250ms ease;
      height: 25px;
      width: 25px;
      margin-left: auto;
      margin-right: 0;
      &:hover {
        opacity: 0.6;
      }
      &:before {
        font: normal normal normal 14px/1 FontAwesome;
        color: inherit;
        content: "\f053";
      }
      &:disabled,
      &.disabled {
        cursor: no-drop;
      }
    }
  }

  &__single-info-items{
    display: flex;
    flex-wrap: wrap;
  }

  &__draggable-item{
    background-color: $overview-component-body-background-color;
    margin: 10px;
    max-width: 1000px;
    height: 350px;
    border-radius: 0px 5px;
    box-sizing: border-box;
    transition: box-shadow 200ms cubic-bezier(0, 0, 0.2, 1);
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);

  }

  &__dynamic{
    position: absolute;
  }

  &__header{
    align-items: center;
    justify-content: space-between;
    padding: 5px 10px;
    display: flex;
    color: $overview-font-color;
    font-size: 14px;
    cursor: move;
    border-radius: 5px 5px 0 0;

    span{
      opacity: 0.8;
      position: relative;
    }
    &--subtitled{
      width: 80%;
      &:after{
        position: absolute;
        top: 1.25rem;
        left: 0;
        content: attr(label);
        font-size: 14px;
      }
    }
  }

  &__container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 90%;
    border-radius: 0 0 5px 5px;
  }

  mat-drawer-container{
    background-color: inherit;
  }

  mat-drawer{
    background-color: $overview-drawer-background-color;
    width: 35%;
    margin: 5px 0;
    height: fit-content;

    &:not(mat-drawer-side){
      box-shadow: -4px -3px 11px 1px rgb(0 0 0 / 20%),
                  -2px 6px 20px 0px rgb(0 0 0 / 14%),
                  -4px 4px 30px 5px rgb(0 0 0 / 12%);
    }
  }
}

.overview-component{
  &__body {
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  &__spinner{
    display: flex;
    flex-direction: column;
    justify-content: center;
    p{
      font-size: 14px;
      opacity: 0.6;
      text-align: center;
    }
  }

}

.info-piece{
  background-color: $overview-component-body-background-color;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100px;
  min-width: 200px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
  0 2px 2px 0 rgba(0, 0, 0, 0.14),
  0 1px 5px 0 rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  margin: 10px;

  span{
    padding: 5px;
  }

  &__body{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__title{
    font-size: 14px;
    position: absolute;
    left: 5px;
    top: 0;
  }

  &__data{
    font-size: 28px;
    font-weight: bold;
    position: relative;

    &:after{
      content: "";
      position: absolute;
      width: 100%;
      height: 4px;
      bottom: 0;
      left: 0;
      display : inline-block;
      background-color: $overview-highlight-color;
    }
  }
}

.overview-drawer{
  padding: 15px 10px 15px 10px;;

  &__header{
    display: flex;
    justify-content: space-between;
    padding-bottom: 25px;
  }

  &__options{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 10px;

    mat-checkbox{
      margin: 5px 10px;
    }

  }

  &__item{
    padding-bottom: 20px;
  }

  &__item-title{
    padding-bottom: 5px;
    font-size: 14px;
    opacity: 0.8;
  }

  &__item-subtitle{
    padding-bottom: 5px;
    font-size: 14px;
    opacity: 0.7;
  }



  &__container{
    padding-left: 5px;
  }

  &__divider{
    margin-bottom: 10px !important;
  }

  &__close {
    background-image: url(/assets/img/close.svg);
    background-repeat: no-repeat;
    background-color: transparent;
    width: 14px;
    height: 14px;
    margin-top: 3px;
    padding: 7px;
    border: 0;
    box-sizing: border-box;
    transition: opacity 250ms ease;
    &:hover {
      opacity: 0.6;
    }
  }

}

.drawer-general-info{
  width: 100%;
  border-radius: 5px;
  background-color: $table-striped-background-color;
  margin-bottom: 20px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
  0 2px 2px 0 rgba(0, 0, 0, 0.14),
  0 1px 5px 0 rgba(0, 0, 0, 0.12);

  div{
    padding: 5px;
    font-size: 14px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  span{
    padding: 5px;
  }

  &__title{
    position: relative;
    &:after{
      content: "";
      position: absolute;
      width: 100%;
      height: 2px;
      bottom: 2px;
      left: 0;
      display : inline-block;
      background-color: $overview-highlight-color;
    }
  }

  &__value{
    max-width: 80%;
  }
}
