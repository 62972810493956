/**
* Default variables file
*
* Use semantic names ($element-link-color: blue),
* instead of value-descriptive names ($blue: blue)
* Variables may change in the future and would
* become useless ($blue: green)
* If you want to know what color it's defined,
* install a plugin in your IDE
*
* To create a new theme, duplicate this file
* and name it whatever you want. To load it:
* styleManager.setStyle('theme', '/assets/themes/your-theme-name.css');
*/
/* You can add global styles to this file, and also import other style files */
@use "@angular/material" as mat;
@include mat.core();

/* LAYOUT */

/* Body */
$body-background-color: #1a1a24;
$body-text-color: #f6f6fa;
$app-background-color: #3b3b44;

/* Header */
$header-background-color: #34343e;
$header-text-color: #f6f6fa;
$header-text-color-active: #ffe600;
$header-logo-image: "/assets/img/ey_logo.svg";
$header-nav-background-color: #1a1a24;
$header-nav-text-color: #f6f6fa;

/* Footer */
$footer-background-color: #2e2e38;
$footer-text-color: #aaaaaa;

/* Links */
$link-color: #5da8f4;
$link-color-hover: #336699;

/* COMPONENTS */

/* Breadcrumb */
$breadcrumb-text-color: #f6f6fa;

/* KPIs */
$kpis-background-color: #2e2e38;
$kpis-text-color: #f6f6fa;
$kpis-text-color-docsnum: #ca3433;
$kpis-text-color-quality: #c981b2;
$kpis-text-color-accuracy: #7fd1d6;
$kpis-text-color-datapoints: #95cb89;
$kpis-text-color-nodata: #505050;
$kpis-text-color-status: #d8d2e0;
$kpis-loading-text-color: #f6f6fa;

/* Pagehead */
$pagehead-background-color: #1a1a24;
$pagehead-title-text-color: #A4A3B1;
$pagehead-tab-background-color: #26262f;
$pagehead-tab-list-secondary-background-color: #34343e;
$pagehead-tab-secondary-background-color: #2e2e38;
$pagehead-tab-secondary-active: #3b3b44;
$pagehead-tab-text-color: #f6f6fa;
$pagehead-tab-background-color-active: #34343e;
$pagehead-tab-text-color-active: #ffe600;
$pagehead-tab-text-color-hover: #f6f6fa;
$pagehead-button-background-color: #2E2E38;
$pagehead-button-text-color: #fff;
$pagehead-button-text-color-disabled: #737387;
$pagehead-button-background-color-hover: #33333E;
$pagehead-button-background-color-active: #3F3F4C;
$pagehead-button-background-color-disabled: #2E2E38;

/* Tables */
$table-background-color: #26262f;
$table-text-color: #DEDEE2;
$table-striped-background-color: transparent;
$table-striped-background-color-odd: #34343e;
$table-sort-icon-color: #f6f6fa;
$table-sort-icon-color-hover: #bebebe;
$table-responsive-head-background-color: #f6f6fa;
$table-responsive-head-text-color: #2e2e38;
$table-selection-background-color: #f6f6fa;
$table-selection-text-color: #f6f6fa;
$table-progress-bar-color: #ffe600;
$table-progress-background-color: #ad9d0a;
$table-checkbox-color: #f6f6fa;
$table-row-hover-outline-color: #85B9FD;

/* Forms and inputs*/
$input-placeholder-text-color: #666666;

/* Searchbox */
$searchbox-background-color: #2e2e38;
$searchbox-text-color: #f6f6fa;
$searchbox-border-color: #7c7c7c;
$searchbox-border-color-focus: #ffe600;
$searchbox-placeholder-text-color: #f6f6fa;

/* Project card (grid) */
$project-card-background-color: #2e2e38;
$project-card-text-color: #f6f6fa;
$project-card-title-color: #f6f6fa;
$project-card-border-color: rgba(0, 0, 0, 0.12);
$project-card-text-color-light: #f6f6fa;
$project-card-text-color-lighter: #f6f6fa;
$project-card-line-color: #f6f6fa;
$project-card-analysis-text-color: #2c973e;
$project-card-document-text-color: #b955b7;

/* Pagination */
$pagination-background-color: transparent;
$pagination-text-color: #fff;
$pagination-link-color: #A4A3B1;
$pagination-link-color-hover: #DEDEE2;
$pagination-current-background-color: #3b3b46;
$pagination-current-text-color: #fff;

/* Modals */
$modal-loader-background-color: #3e3d3e;
$modal-loader-text-color: #f6f6fa;
$modal-background-color: #23232F;
$modal-text-color: #DEDEE2;
$modal-warning-color: #ffe600;
$modal-prohibited-color: #d93131;

/* Spinner */
$spinner-color-light: #ffe600;
$spinner-color-dark: #2e2e38;

/* Marks */
$mark-background-color: #ffe600;

/* Overview */
$overview-component-header-background-color: #2e2e38;
$overview-component-body-background-color: #2e2e38;
$overview-font-color: #f6f6fa;
$overview-highlight-color: #ffe600;
$overview-drawer-background-color: #3b3b44;

/* Charts */
$chart-title-color: #f6f6fa;
$chart-axis-line-color: rgba(7, 6, 6, 0.4);
$chart-axis-text-color: #f6f6fa;
$chart-tooltip-background-color: #2e2e38;
$chart-tooltip-text-color: #f6f6fa;

/* PAGES */

/* Project list page */
$projectlist-text-color: #fff;
$projectlist-button-background-color: #2e2e38;
$projectlist-button-text-color: #f6f6fa;
$projectlist-button-background-color-hover: #747480;
$projectlist-button-background-color-active: #747480;

/* Login page */
$login-title-color: #f6f6fa;
$login-text-color: #f6f6fa;
$login-placeholder-color: #f6f6fa;
$login-box-border-color: #9d9d9d;
$login-box-text-color: aliceblue;
$login-description-color: #999999;
$login-forgotpass-color: #999999;
$login-forgotpass-color-hover: #f6f6fa;
$login-background-image: "/assets/img/login/login_bg.jpg";

/* Document view page */
$docview-nav-background-color: #2e2e38;
$docview-nav-border-color: #7c7c7c;
$docview-nav-text-color: #f6f6fa;
$docview-pagination-text-color: #2e2e38;
$docview-nav-text-color-hover: #afafaf;

/* Query page */
$query-text-color: #f6f6fa;
$query-background-image: "/assets/img/Brain_2.svg";

/* STATUS */

/* Status */
$nostatus-color: #9c9c9c;
$nostatus-color-dark: #747480;
$success-color-dark: #419145;
$partial-success-color: #d2d040;
$partial-success-color-dark: #8f9e20;
$info-color: #00bbd3;
$info-color-dark: #0096a6;
$warning-color: #fe9700;
$warning-color-dark: #f47b00;
$danger-color: #d65b5b;
$danger-color-dark: #d93131;
$not-started-color: #9c9c9c;
$started-color: #00bbd3;
$training-color: #0096a6;
$completed-color: #4bae4f;
$transcribing-color: #0096a6;
$processing-color: #00bbd3;
$analyzing-color: #0096a6;
$failed-color: #d93131;
$success-color: #4bae4f;

/*Text Element*/
$ner-entity-color: cornflowerblue;
$luis-entity-color: lightgreen;
$emphasized-entity-color: yellow;

/* Tooltips */
$tooltip-with-border-background-color: #f6f6fa;
$tooltip-with-border-arrow-base: 1.5em;
$tooltip-with-border-arrow-length: 0.75em;
$tooltip-with-border-border-width: 0.05em;
$tooltip-with-border-border-color: #999;
$tooltip-with-border-transition-out-duration: 0.2s;

$tooltip-without-border-background-color: #3e3d3e;
$tooltip-border-color: #2e2e38;
$tooltip-without-border-arrow-base: 1em;
$tooltip-without-border-arrow-length: 0.5em;
$tooltip-without-border-transition-out-duration: 0.2s;

//Speakers
$speaker-colors: #98c1d9, #e56b6f, #9d4edd, #ff4136, #ff6d00, #2db757, #27aaaa,
  #188ce5, #3d108a, #750e5c;

/* Material theme variables */

// EY palette generated with http://mcg.mbitson.com/#!?eypalette=%23FFE600&themename=EYpalette
$EYpalette: (
  50: #ffe600,
  100: #ffe600,
  200: #ffe600,
  300: #ffe600,
  400: #ffe600,
  500: #ffe600,
  600: #ffe300,
  700: #ffdf00,
  800: #ffdb00,
  900: #ffd500,
  A100: #ffffff,
  A200: #fffdf2,
  A400: #fff3bf,
  A700: #ffeea6,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);
// EY white palette generated with http://mcg.mbitson.com
$EYwhitepalette: (
  50: #fefefe,
  100: #fcfcfe,
  200: #fbfbfd,
  300: #f9f9fc,
  400: #f7f7fb,
  500: #f6f6fa,
  600: #f5f5f9,
  700: #f3f3f9,
  800: #f2f2f8,
  900: #efeff6,
  A100: #ffffff,
  A200: #ffffff,
  A400: #ffffff,
  A700: #ffffff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);
// EY error palette generated with http://mcg.mbitson.com
$EYerrorpalette: (
  50: #fbeaea,
  100: #f4caca,
  200: #eda7a7,
  300: #e58383,
  400: #e06969,
  500: #da4e4e,
  600: #d64747,
  700: #d03d3d,
  800: #cb3535,
  900: #c22525,
  A100: #fffefe,
  A200: #ffcbcb,
  A400: #ff9898,
  A700: #ff7f7f,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$EY-app-primary: mat.define-palette($EYpalette);
$EY-app-secondary: mat.define-palette($EYwhitepalette);
$EY-app-warn: mat.define-palette($EYerrorpalette);
$EY-app-theme: mat.define-dark-theme(
  $EY-app-primary,
  $EY-app-secondary,
  $EY-app-warn
);
@include mat.all-component-themes($EY-app-theme);

/* Import all styles with previus defined variables  */
@import "../index";
