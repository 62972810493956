/**
* Search input (search-box) styles
*/

.search-box {
	position: relative;
	color: $searchbox-text-color;
	font-size: 14px;
	input {
		background-color: $searchbox-background-color;
		border-radius: 20px;
		border: 1px solid $searchbox-border-color;
		color: inherit;
		width: 100%;
		padding: 5px 30px 5px 12px;
		&:focus {
			outline: 0;
	    border-color: $searchbox-border-color-focus;
		}
		&::placeholder {
			color: $searchbox-placeholder-text-color;
			font-weight: lighter;
		}
		+ button {
			position: absolute;
			right: 6px;
			top: 5.5px;
			background: transparent;
			border: 0;
			font: normal normal normal 14px/1 FontAwesome;
			color: inherit;
			outline: 0 !important;
			opacity: 0.5;
			transition: opacity 200ms ease;
			&:hover {
				opacity: 1;
			}
			&:before {
				content: "\f002";
				width: 100%;
				height: 100%;
				display: block;
			}
		}
	}
}
