.filter {
  $margin: 12px;

  .title {
    margin: $margin;
    font-size: 18px;
  }

  .field {
    margin: $margin;

    mat-form-field {
      width: 100%;

      .mdc-text-field--filled:not(.mdc-text-field--disabled) {
        background-color: transparent;
      }
    }
  }
}
