$btn-margin: 12px;

.btn-set {
  margin-left: -$btn-margin;
  width: fit-content;
}

@mixin btn($color, $label-color, $border-color) {
    background-color: $color;
    border: solid 1px $border-color;
    color: $label-color;

    padding: 8px 16px;
    margin-left: $btn-margin;
}

.btn-basic {
    @include btn(#3b3b44, #eee, #eee);
}

.btn-confirm {
    @include btn(#ffe600, #000, transparent);
}

