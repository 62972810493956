/**
* Pagination component styles
*/

.custom-pagination {
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	min-height: 25px;
	background: $pagination-background-color;
	color: $pagination-text-color;
	padding-bottom: 5px;
	padding-top: 5px;
	text-align: right;
	font-size: 12px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

	.ngx-pagination {
		margin-bottom: 0 !important;
		position: relative;

		a {
			color: $pagination-link-color;
			border-radius: 12px;
			&:hover {
				text-decoration: none;
				color: $pagination-link-color-hover;
			}
		}
		.current {
			border-radius: 12px;
			background: $pagination-current-background-color !important;
			color: $pagination-current-text-color;
		}
	}
	.show-for-sr {
		display: none;
	}

	.la-ball-circus {
    color: #808080;
    color: #808080;
    right: 70px;
    top: 8px;
    position: absolute !important;
  }
}

.modal-pagination {
  position: inherit;
	width: 100%;
	min-height: 25px;
	background-color: $table-background-color;
	color: $pagination-text-color;
	padding-bottom: 5px;
	padding-top: 5px;
	text-align: right;
	font-size: 12px;

	.ngx-pagination {
		margin-bottom: 0 !important;
		position: relative;

		a {
			color: $pagination-link-color;
			border-radius: 12px;
			&:hover {
				text-decoration: none;
				color: $pagination-link-color-hover;
			}
		}
		.current {
			border-radius: 12px;
			background: $pagination-current-background-color !important;
			color: $pagination-current-text-color;
		}
	}
	.show-for-sr {
		display: none;
	}

	.la-ball-circus {
    color: #808080;
    color: #808080;
    right: 70px;
    top: 8px;
    position: absolute !important;
  }
}
