/*
* Theme styles loader
* Used to load style variables into css classes
* to allow different component styles
* Component should not import theme variables
*/


/* Layout colors */
.app-styles {
  //background: $app-background-color;
}

/* Header colors */
.header-styles {
  background-color: $body-background-color;
  color: $header-text-color;

  a,
  .as-link {
    color: $header-text-color !important;

    &:hover {
      color: $header-text-color-active !important;
    }

    &.active {
      color: $header-text-color-active !important;
    }
  }

  .topnav__user path {
    fill: $header-text-color !important;
  }
}

/* Sidebar colors */
.sidebar-styles {
  background-color: $header-nav-background-color;
  color: $header-nav-text-color;
  a,
  .as-link {
    color: $header-text-color !important;
    opacity: 0.6;
    transition: opacity 200ms ease;
    border-left-color: transparent;

    &:hover {
      opacity: 0.9;
    }

    &.active {
      opacity: 1;
      border-left-color: $header-text-color-active;
    }
  }
}

/* Footer colors */
.footer-styles {
  background: $footer-background-color;
  color: $footer-text-color;
}

.hamburger {
  &-inner {
    background: $header-text-color !important;
    &:before,
    &:after {
      background: $header-text-color !important;
    }
  }
}

.header-logo-styles {
  background-image: url($header-logo-image);
}

@media only screen and (min-width: 768px) {
  .header-styles {
    a,
    .as-link {
      &.active {
        border-bottom: 2px solid $header-text-color-active !important;
      }
    }
  }
}

/* Breadcrumb colors */
.custom-breadcrumbs {
  color: $breadcrumb-text-color;
}

/* Projectlist header colors */
.projectlist-title {
  color: $projectlist-text-color;
}

.projectlist-button {
  background-color: $projectlist-button-background-color;
  color: $projectlist-button-text-color;

  &:hover {
    background-color: $projectlist-button-background-color-hover;
  }

  &.active {
    background-color: $projectlist-button-background-color-active;
  }
}

/* Spinner colors */
.spinner-ball-styles {
  color: $spinner-color-light;

  &.la-dark {
    color: $spinner-color-dark;
  }
}

/* KPIs colors */
.kpis-background-color {
  background-color: $kpis-background-color;
}

.kpis-text-color {
  color: $kpis-text-color;
}

.kpis-text-color-docsnum {
  color: $kpis-text-color-docsnum;
}

.kpis-text-color-quality {
  color: $kpis-text-color-quality;
}

.kpis-text-color-accuracy {
  color: $kpis-text-color-accuracy;
}

.kpis-text-color-datapoints {
  color: $kpis-text-color-datapoints;
}

.kpis-text-color-nodata {
  color: $kpis-text-color-nodata;
}

.kpis-text-color-status {
  color: $kpis-text-color-status;
}

.kpis-loading-text-color {
  color: $kpis-loading-text-color;
}

/* Status colors */
.color-nostatus {
  color: $nostatus-color;
}

.color-nostatus-dark {
  color: $nostatus-color-dark;
}

.color-success {
  color: $success-color;
}

.color-success-dark {
  color: $success-color-dark;
}

.color-partial-success {
  color: $partial-success-color;
}

.color-partial-success-dark {
  color: $partial-success-color-dark;
}

.color-info {
  color: $info-color;
}

.color-info-dark {
  color: $info-color-dark;
}

.color-warning {
  color: $warning-color;
}

.color-warning-dark {
  color: $warning-color-dark;
}

.color-danger {
  color: $danger-color;
}

.color-danger-dark {
  color: $danger-color-dark;
}


/* Project list cards */
.project-card-styles {
  background-color: $project-card-background-color;
  color: $project-card-text-color;
  border-color: $project-card-border-color;

  .projectcard__dates {
    color: $project-card-text-color;
  }
}

.project-card-title-color {
  color: $project-card-title-color;
}

.project-card-text-color-light {
  color: $project-card-text-color-light;
}

.project-card-text-color-lighter {
  color: $project-card-text-color-lighter;
}

.project-card-line-color {
  border-color: $project-card-line-color;
}

.project-card-analysis-text-color {
  color: $project-card-analysis-text-color;
}

.project-card-document-text-color {
  color: $project-card-document-text-color;
}

/* Login page */
.login-title-color {
  color: $login-title-color;
}

.login-text-color {
  color: $login-text-color;
}

.login-input-styles {
  border-color: $login-box-border-color;
  color: $login-box-text-color;

  &::-webkit-input-placeholder {
    color: $login-placeholder-color;
  }

  &::-moz-placeholder {
    color: $login-placeholder-color;
  }

  &:-ms-input-placeholder {
    color: $login-placeholder-color;
  }

  &:-moz-placeholder {
    color: $login-placeholder-color;
  }
}

.login-forgotpass-color {
  color: $login-forgotpass-color;

  &:hover {
    color: $login-forgotpass-color-hover;
  }
}

.login-background-image {
  background-image: url($login-background-image);
}


/* Query list page */
.query-text-color {
  color: $query-text-color;
}

.query-background-image {
  background-image: url($query-background-image);
}

/* Document view page */
.docview-nav-styles {
  color: $docview-nav-text-color;
  background-color: $docview-nav-background-color;
  border: 1px solid $docview-nav-border-color;

  &:hover {
    color: $docview-nav-text-color-hover;
  }
}

.export-button-styles {
  .exportbtn__drop-item {
    background: #2E2E38;
    color: #F6F6FA;
    &:hover {
      background: #747480;
    }
  }
}
