/**
* Page controls styles
* Please use this file only to style pagecontrol-related properties
*/
.pagectrl {
  padding-left: 10px;
  &__wrap {
    display: flex;
    flex-wrap: wrap;
  }
  &__title {
    flex: 1 1 50%;
    h1 {
        font-size: 30px;
        line-height: 1.5;
        margin: 6px 0;
      @media (max-width: 1270px) {
        line-height: 1;
      }
    }
  }
  &__search {
    flex: 1 1 50%;
    margin-top: 5px;
  }
  &__buttons-block {
    flex: 1 1 auto;
    margin-top: 5px;
    &--large {
      text-align: right;
    }
  }
  &__button {
    display: inline-block;
    vertical-align: top;
    min-width: 20px;
    height: 20px;
    padding: 5px;
    border: 0;
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: center;
    box-shadow: none;
    outline: 0 !important;
    transition: background 200ms ease;
    box-sizing: content-box;
    font-size: 12px;
    + .pagectrl__button {
      margin-left: 2px;
    }
    &--list {
      background-image: url('../../../../assets/img/icons/list-0.svg');
    }
    &--grid {
      background-image: url('../../../../assets/img/icons/grid.svg');
    }
    &.active {
      cursor: default;
    }
  }
}

@media (min-width: 650px) {
  .pagectrl {
    &__title {
      flex: 1 1 auto;
    }
    &__search {
      flex: 0 1 200px;
    }
    &__buttons-block {
      flex: 0 1 auto;
      text-align: right;
      margin-left: 10px;
    }
  }
}

@media (min-width: 768px) {
  .pagectrl {
    &__title {
      h1 {
        margin: 0;
        font-family: ey-light;
      }
    }
  }
}
