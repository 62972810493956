@mixin getEntityStyle($color, $margin) {
  background-color: $color !important;
  display: inline-block;
  line-height: 1;
  border-radius: 0.25em;
  margin: $margin;
  padding: 4px 1px 4px 3px;
  &:after{
    color: black;
    content: attr(label);
    background-color: $table-text-color;
    font-size: 14px;
    border-radius: 5%;
    font-weight: bold;
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
    padding: 0.35em 0.35em;
    margin: 0 2px 0px 5px;
    border-radius: 0.35em;
  }
}

.text-element{
  &--languagemodel{
    color: $table-text-color;
  }

  &--chat{
    color: black;
  }

  &__text{

    &--selectable{
      padding: 2px 5px;
      outline: 1px solid transparent;
      cursor: pointer;
      &:hover{
        outline-color: $table-row-hover-outline-color;
      }
    }

    &--not-selectable{
      opacity: 0.6;
      padding: 2px 5px;
    }

    &--selected{
      padding: 2px 5px;
      background-color: $table-striped-background-color;
      outline: 1px solid $table-row-hover-outline-color;
    }

    &--is-ner{
      @include getEntityStyle($ner-entity-color, 0 0.25em);
    }
    &--is-luis{
      @include getEntityStyle($luis-entity-color, 0 0.25em);
    }
    &--is-mixed{
      @include getEntityStyle($ner-entity-color, 0);
    }

    &--emphasized{
      background-color: $emphasized-entity-color !important;
    }
  }

  &__mixed-entity{
    background-color: $luis-entity-color !important;
    display: inline-block;
    line-height: 1;
    border-radius: 0.25em;
    margin: 0 0.25em;
    padding: 0.5px 1px 0.5px 0.5px;
    &:after{
      content: attr(label);
      background-color: $table-text-color;
      font-size: 14px;
      border-radius: 5%;
      font-weight: bold;
      display: inline-block;
      vertical-align: middle;
      line-height: 1;
      padding: 0.35em 0.35em;
      margin: 0 2px 0px 5px;
      border-radius: 0.35em;
    }
  }

  &__entity{
    background-color: $table-text-color;
    font-size: 14px;
    border-radius: 5%;
    padding: 0px 1px;
    margin: 0px 1px;
  }

  &__white-space{
    white-space:  pre;

    &--emphasized{
      background-color: $emphasized-entity-color;
    }

    &--selected{
      background-color: $luis-entity-color;
    }
  }



  &__regular{
    background-color: transparent;
  }
}

.textelement-popover{
  &__header{
    color:$table-text-color;
    font-size: 14px;
    font-weight: bold;
  }
  &__content{
    color:$table-text-color;
    font-size: 14px;
    margin: 5px 0px;
  }
}
