/**
* Main styles file
*
* Please use this file only if there isn't a specific stylesheet
* to your additions
*/

/* Body */
body {
  background-color: $body-background-color;
  color: $body-text-color;
}

/* Tab container */
.tab-content {
  padding: 10px 10px;
  // padding: 10px 10px 50px;
  &--nopadding {
    padding: 0;
  }
}

/*
* Common element styling
*/

button {
  outline: 0 !important;
}

/* Placeholders */
::-webkit-input-placeholder {
  color: $input-placeholder-text-color;
}

::-moz-placeholder {
  color: $input-placeholder-text-color;
}

:-ms-input-placeholder {
  color: $input-placeholder-text-color;
}

:-moz-placeholder {
  color: $input-placeholder-text-color;
}

/* hide scrollbars */
// ::-webkit-scrollbar {
//   display: none;
// }
// * {
//   scrollbar-width: none;
// }

/* style scrollbars */
::-webkit-scrollbar {
  width: 8px !important;
}

/* Track */
::-webkit-scrollbar-track {
  // background: #f1f1f1 !important;
  background: #3b3b44 !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888 !important;
  border-radius: 10px !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555 !important;
  // background: #26262F !important;
}

/* Texts */
p {
  margin: 0px;
}

mark {
  background-color: $mark-background-color;
}

/* Tables */
table {
  font-size: 14px;
}
