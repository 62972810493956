/**
* Datamodel validation form styles
* Please use this file only to style datamodel validation form related properties
*/
$dataval-general-padding: 0 10px;
$dataval-head-background-color: #26262F;
$dataval-body-background-color: #2E2E38;
$dataval-light-color: #F6F6FA;
$dataval-active-color: #FFE600;
$dataval-error-color: #DA4E4E;

.dataval {
  &__wrap {
    padding: 10px;
    &--nopadding {
      padding: 0;
    }
  }

  /* Rows and divisions */
  &__row {
    padding: $dataval-general-padding;
    display: block;

    h5,
    h6 {
      margin-top: 12px;
    }

    &--flex {
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      > * {
        padding: $dataval-general-padding;
        box-sizing: border-box;
      }
      > .dataval__row--flex {
        padding: 0;
      }
    }
    &--nopadding {
      > div:last-child {
        padding-right: 0;
      }
      > div:first-child {
        padding-left: 0;
      }
    }
    &--top-padded {
      padding-top: 10px;
    }
    &--bottom-padded {
      padding-bottom: 10px;
    }
    &--top-margin {
      margin-top: 10px;
    }
    &--bottom-margin {
      margin-bottom: 10px;
    }
    &--errors {
      color: $dataval-error-color;
      font-size: 14px;
    }
    &--alert {
      font-size: 14px;
      margin-bottom: 12px;
      color: #DA4E4E;
    }
  }
  &__midrow {
    flex: 1 1 50%;
    &--nogrow {
      flex-grow: 0;
    }
    &--withicon {
      flex-basis: calc(50% - 20px);
    }
  }
  &__thirow {
    flex: 1 1 33%;
    &--nogrow {
      flex-grow: 0;
    }
  }
  &__fourow {
    flex: 1 1 25%;
    &--nogrow {
      flex-grow: 0;
    }
  }
  &__fifrow {
    flex: 1 1 20%;
    &--nogrow {
      flex-grow: 0;
    }
  }
  &__minrow {
    flex: 0 1 auto;
  }

  /* General */
  &__box {
    background: $dataval-body-background-color;
    margin-bottom: 10px;
    &--invalid {
      > .dataval__box-head {
        border-left: 38px solid $dataval-error-color;
        &:before {
          content: "\f071";
          position: absolute;
          font: normal normal normal 12px/1 FontAwesome;
          top: 13px;
          left: -25px;
        }
      }
    }
  }

  /* Boxs */
  &__box {
    outline: 1px solid transparent;
    transition: outline-color 250ms ease;
    background: $dataval-body-background-color;
    margin-bottom: 10px;
    &--expanded {
      > .dataval__box-body {
        display: block;
      }
      > div > .dataval__box-title:before {
        border: 5px solid transparent;
        border-top: 7px solid $dataval-light-color;
        margin-top: 5px;
        margin-left: 1px;
      }
    }
    &--invalid {
      > .dataval__box-head {
        border-left: 38px solid $dataval-error-color;
        &:before {
          content: "\f071";
          position: absolute;
          font: normal normal normal 12px/1 FontAwesome;
          top: 13px;
          left: -25px;
        }
      }
    }
    .dataval__box {
      background: #3b3b44;
      .dataval__box {
        background: $dataval-body-background-color;
        .dataval__box {
          background: #3b3b44;
          .dataval__box {
            background: $dataval-body-background-color;
          }
        }
      }
    }
  }
  &__box-head {
    background: $dataval-head-background-color;
    padding: 7px 14px 7px 10px;
    display: flex;
    position: relative;
    word-break: break-word;
    &:hover {
      .dataval__box-controls {
        opacity: 1;
      }
    }
  }
  &__box-title {
    flex: 1 1 auto;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    padding-left: 12px;
    &:before {
      content: "";
      display: inline-block;
      vertical-align: top;
      position: absolute;
      top: 10px;
      left: 7px;
      border: 5px solid transparent;
      border-left: 7px solid $dataval-light-color;
      margin-top: 3px;
      margin-left: 3px;
    }
    &--general {
      padding-left: 0;
      cursor: default;
      &:before {
        display: none;
      }
    }
  }
  &__box-title-error {
    line-height: 24px;
    font-size: 14px;
    color: $dataval-error-color;
    text-align: right;
    padding-left: 5px;
  }
  &__box-controls {
    flex: 0 0 auto;
    opacity: 0;
    padding: 0 0 0 10px;
    transition: opacity 250ms ease;
    button {
      background: transparent;
      border: none;
      padding: 0;
      color: $dataval-light-color;
      &:before {
        font: normal normal normal 14px/1 FontAwesome;
        transition: color 250ms ease;
      }
      &:hover:before {
        color: $dataval-active-color;
      }
    }
  }
  &__box-remove:before {
    content: "\f1f8";
  }
  &__box-body {
    padding: 10px 0;
    display: none;
    &--general {
      display: block;
    }
  }

  /* Buttons */
  &__row--buttons {
    text-align: right;
    button + button {
      margin-left: 6px;
    }
  }

  /* Inputs (only in datamodel validations form) */
  .mat-form-field {
    width: 100%;
  }
  .mat-checkbox-layout {
    margin-top: 11px;
    margin-bottom: 6px;
  }
  .mat-slide-toggle {
    margin-top: 14px;
    margin-bottom: 18px;
  }
  .mat-icon-button {
    margin-top: 9px;
    font-size: 20px;
  }

  /* Utility classes (only in datamodel validations form) */
  .no-horizontal-padding {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
