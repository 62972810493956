.reviewers {
  &__head {
    padding: 10px 10px 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  &__head-wrap {
    display: flex;
    .reviewers__title {
      flex: 1 1 auto;
    }
  }
  &__title {
    font-size: 16px;
    line-height: 1;
  }
  &__button {
    background: transparent;
    border: 1px solid $body-text-color;
    color: $body-text-color;
    transition: opacity 250ms ease;
    &:hover {
      opacity: 0.6;
    }
    &:before {
      font: normal normal normal 14px/1 FontAwesome;
      color: inherit;
    }
    &:disabled,
    &.disabled {
      cursor: no-drop;
    }
    &--filter {
      align-self: baseline;
      height: 25px;
      &:before {
        content: '\f0b0';
      }
    }
    &--new:before {
      content: '\f067';
    }

    &--update:before {
      content: '\f021';
    }

    &--send:before {
      content: '\f1d8';
    }

    &--download:before {
      content: '\f019';
    }

    &--consolidate-all:before {
      content: '\f0c5';
    }

    &--train:before {
      content: '\f013';
    }

    &--upload:before {
      content: '\f093';
    }

    &--record:before {
      content: '\f130';
    }
  }
  &__filters {
    flex: 1 1 100%;
    padding: 5px 0 10px;
  }
}
