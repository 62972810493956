/**
* Reset styles
* Don't change this file, it's used to override default browsers styles
* More info about reset files: https://es.wikipedia.org/wiki/Reset_CSS
*/
a,abbr,acronym,address,applet,big,blockquote,body,caption,cite,code,dd,del,dfn,div,dl,dt,em,fieldset,font,form,h1,h2,h3,h4,h5,h6,html,iframe,ins,kbd,label,legend,li,object,ol,p,pre,q,s,samp,small,span,strike,strong,sub,sup,table,tbody,td,tfoot,th,thead,tr,tt,ul,var{border:0;font-family:inherit;font-size:100%;font-style:inherit;font-weight:inherit;margin:0;outline:0;padding:0;vertical-align:baseline}
article,aside,details,figcaption,figure,footer,header,hgroup,nav,section{display:block;font-family:sans-serif}
audio,canvas,video{display:inline-block;max-width:100%}
html{-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;min-height:100%}
body,button,input,select,textarea{color:#2b2b2b;font-family:sans-serif;font-size:16px;font-weight:400;line-height:1.5}
body{min-height:100%;margin: 0px;padding: 0px;overflow-x: hidden;}
span{color:inherit}
a{text-decoration:none;cursor:pointer}
a:focus{outline:0}
a:active,a:hover{outline:0}
h1,h2,h3,h4,h5,h6{font-family:inherit;clear:both}
h1{font-size:26px;line-height:1.38}
h2{font-size:32px;line-height:1}
h3{font-size:22px;line-height:1.09;}
h4{font-size:20px;line-height:1.2}
h5{font-size:16px;line-height:1.33}
h6{font-size:14px;line-height:1.33}
address{font-style:italic;margin-bottom:24px}
abbr[title]{border-bottom:1px dotted #2b2b2b;cursor:help}
b,strong{font-weight:700}
cite,dfn,em,i{font-style:italic}
ins,mark{background:#fff9c0;text-decoration:none}
p{margin-bottom:24px;word-wrap:break-word;line-height:1.33}
code,kbd,pre,samp,tt,var{font-family:monospace,serif;font-size:15px;-webkit-hyphens:none;-moz-hyphens:none;-ms-hyphens:none;hyphens:none;line-height:1.6}
pre{border:1px solid rgba(0,0,0,.1);-webkit-box-sizing:border-box;-moz-box-sizing:border-box;box-sizing:border-box;margin-bottom:24px;max-width:100%;overflow:auto;padding:12px;white-space:pre;white-space:pre-wrap;word-wrap:break-word}
blockquote,q{-webkit-hyphens:none;-moz-hyphens:none;-ms-hyphens:none;hyphens:none;quotes:none}
blockquote:after,blockquote:before,q:after,q:before{content:"";content:none}
blockquote{font-style:normal;font-weight:400;line-height:1.3;margin:24px 0}
blockquote cite,blockquote small{color:#2b2b2b;font-size:16px;font-weight:400;line-height:1.5}
blockquote cite,blockquote em,blockquote i{font-style:normal}
blockquote b,blockquote strong{font-weight:400}
small{font-size:smaller}
big{font-size:125%}
sub,sup{font-size:75%;height:0;line-height:0;position:relative;vertical-align:baseline}
sup{bottom:1ex}
sub{top:.5ex}
dl{margin-bottom:24px}
dt{font-weight:700}
dd{margin-bottom:24px}
ol,ul{list-style:none;margin:0 0 24px 20px}
ul{list-style:disc}
ol{list-style:decimal}
li>ol,li>ul{margin:0 0 0 20px}
img{-ms-interpolation-mode:bicubic;border:0;vertical-align:middle}
figure{margin:0}
fieldset{border:1px solid rgba(0,0,0,.1);margin:0 0 24px;padding:11px 12px 0}
legend{white-space:normal}
button,input,select,textarea{-webkit-box-sizing:border-box;-moz-box-sizing:border-box;box-sizing:border-box;font-size:100%;margin:0;max-width:100%;vertical-align:baseline}
button,input{line-height:normal}
input,textarea{background-image:-webkit-linear-gradient(hsla(0,0%,100%,0),hsla(0,0%,100%,0));text-align:inherit}
button,html input[type=button],input[type=reset],input[type=submit]{-webkit-appearance:button;cursor:pointer}
button[disabled],input[disabled]{cursor:default}
input[type=checkbox],input[type=radio]{padding:0}
input[type=search]{-webkit-appearance:textfield}
input[type=search]::-webkit-search-decoration{-webkit-appearance:none}
button::-moz-focus-inner,input::-moz-focus-inner{border:0;padding:0}
textarea{overflow:auto;vertical-align:top}
table,td,th{border:1px solid rgba(0,0,0,.1)}
table{border-collapse:separate;border-spacing:0;border-width:1px 0 0 1px;width:100%}
caption,td,th{font-weight:400;text-align:left}
th{border-width:0 1px 1px 0;font-weight:700}
td{border-width:0 1px 1px 0}
del{color:#767676}
hr{background-color:rgba(0,0,0,.1);border:0;height:1px;margin-top:20px;margin-bottom:30px}
::selection{background:#087be3;color:#fff;text-shadow:none}
::-moz-selection{background:#087be3;color:#fff;text-shadow:none}
li,ul{list-style:none;margin:0;padding:0}
ins,mark{background:0 0;text-decoration:none}
.clear{clear:both}
.hidden{display:none!important}
