/**
* Document validation collapsible list styles
*/

$color-primary: #ffe600; // Yellow
$color-light: #f6f6fa; // White
$color-success: #47b04d; // Green
$color-failure: #da4e4e; // Red

$color-grey-1: #26262f; // Darkest grey

%status-item {
  flex: 0 0 90px;
  position: relative;
  padding: 8px 12px;
  &:before {
    content: "";
    width: 10px;
    height: 10px;
    background: grey;
    border-radius: 10px;
    position: absolute;
    top: 12px;
    left: -3px;
  }
}

.valcollaplist {
  margin: 0 0 40px;
  font-size: 14px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);

  &__header {
    display: flex;
    background: $color-grey-1;
    padding: 8px 12px;
    line-height: 2.5;
  }
  &__title {
    flex: 1 1 auto;
  }
  &__collapse {
    flex: 0 0 20px;
    cursor: pointer;
    transition: color 250ms ease;
    &:hover {
      color: $color-primary;
    }
    &:before {
      content: "\f065";
      font: normal normal normal 14px/1 FontAwesome;
      text-rendering: auto;
      display: block;
      margin: 8px auto;
      width: 14px;
    }
    &.is-collapsed:before {
      content: "\f066";
    }
  }

  &__version {
    background: $color-grey-1;
    color: $color-light;
    padding: 4px 12px 10px;
    font-size: 14px;
    line-height: 1.33;
    outline: 1px solid transparent;
    transition: outline 250ms ease;
    display: flex;
  }

  &__icons-wrap {
    display: flex;
    margin-right: 10px;
  }

  &__icons {
    flex: 0 0 20px;
    cursor: pointer;
    transition: color 250ms ease;

    &:hover {
      color: $color-primary;
    }

    &--disabled {
      cursor: not-allowed;
      opacity: 0.5;

      &:hover {
        color: inherit;
      }
    }

    &:before {
      font: normal normal normal 14px/1 FontAwesome;
      text-rendering: auto;
      display: block;
      margin: 10px 4px 10px 0;
      width: 14px;
    }

    &--rerun:before {
      content: "\f01e";
    }

    &--exclude:before {
      content: "\f058";
    }
  }

  &__body {
    background: #34343E;

    &.is-collapsed {
      display: none;
    }

    .valcollaplist {
      &__row {
        display: flex;
        outline: 1px solid transparent;
        transition: outline 250ms ease;
        border-bottom: 1px solid rgba(0, 0, 0, 0.3);
        &:hover {
          outline-color: $color-primary;
        }
      }
      &__item-name {
        flex: 1 1 auto;
        padding: 8px 12px;
        cursor: pointer;
        &:before {
          content: "";
          display: inline-block;
          border: 5px solid transparent;
          border-top: 6px solid $color-light;
          margin-right: 4px;
          vertical-align: top;
          margin-top: 4px;
        }
        &.is-collapsed:before {
          border: 5px solid transparent;
          border-top-color: transparent;
          border-left: 7px solid $color-light;
          margin-top: 2px;
        }
      }
      &__item-status {
        @extend %status-item;
        &--success:before,
        &--manual-success:before {
          background: $color-success;
        }
        &--failure:before,
        &--manual-failure:before {
          background: $color-failure;
        }
        &--text {
          flex: 0 0 65px;
        }
      }
    }

    ul {
      margin: 0;
      &.is-collapsed {
        display: none;
      }
    }

    ul .valcollaplist__item-name {
      padding-left: calc(15px * 1 + 12px);
    }

    ul ul .valcollaplist__item-name {
      padding-left: calc(15px * 2 + 12px);
    }

    ul ul ul .valcollaplist__item-name {
      padding-left: calc(15px * 3 + 12px);
    }

    ul ul ul ul .valcollaplist__item-name {
      padding-left: calc(15px * 4 + 12px);
    }

    ul ul ul ul ul .valcollaplist__item-name {
      padding-left: calc(15px * 5 + 12px);
    }
  }

  &__detail {
    background: #3B3B44;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    display: block;
    &--loading {
      padding: 20px;
    }
    &.is-collapsed {
      display: none;
    }

    .valcollaplist__version {
      padding-left: calc(15px * 2 + 12px);
    }
  }

  &__summary {
    display: flex;
    color: $color-light;
    margin-bottom: 2px;
    &--success,
    &--manual-success {
      .valcollaplist__summary-msg {
        color: #1D9F23;
      }
      color: #1D9F23;
    }
    &--failure,
    &--manual-failure {
      .valcollaplist__summary-msg {
        color: $color-failure;
      }
      color: $color-failure;
    }
    &--failure-not-applicable {
      .valcollaplist__summary-msg {
        color: #9E9EA5;
      }
      color: #9E9EA5;
    }
    .valcollaplist__summary-list {
      li,
      ul {
        list-style-type: disc;
      }
      padding-left: 20px;
    }
  }
  &__summary-msg {
    padding: 12px;
    flex: 1 1 auto;
    line-height: 1.5;
  }
  &__summary-controls {
    display: flex;
    align-items: flex-start;
    flex-shrink: 0;
    padding-right: 12px;
    padding-top: 12px;
    padding-bottom: 12px;
  }
  &__summary-control {
    flex: 1 1 auto;
    transition: color 250ms ease;
    cursor: pointer;
    &:hover {
      color: $color-primary;
    }
    &:before {
      font: normal normal normal 18px/1 FontAwesome;
      text-rendering: auto;
      margin: 14px 6px;
      display: block;
    }
    &--override:before {
      content: "\f1e2";
    }
    &--rerun:before {
      content: "\f01e";
    }
  }
  &__summary-list {
    padding-left: 20px;;
    li {
      list-style-type: disc;
    }
  }

  &__summary-detail{
    &:hover {
      font-weight: bold;
      font-size: 105%;
      cursor: pointer;
    }
    &--active {
      font-weight: bold;
      font-size: 105%;
      cursor: pointer;
    }
  }

  &__condition {
    display: flex;
  }
  &__condition-msg {
    padding: 8px 12px 8px 14px;
    flex: 1 1 auto;
    &:before {
      content: "\f111";
      font: normal normal normal 14px/1 FontAwesome;
      font-size: inherit;
      text-rendering: auto;
      display: inline-block;
      font-size: 14px;
      vertical-align: middle;
      margin: -1px 4px 0 0;
    }
  }
  &__condition-status {
    @extend %status-item;
    &--success:before,
    &--manual-success:before {
      background: $color-success;
    }
    &--failure:before,
    &--manual-failure:before {
      background: $color-failure;
    }
  }

  &__controls {
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
  }
  &__control {
  }
  &__control-btn {
    font-size: 14px;
    line-height: 2 !important;
  }
  &__override {
    flex: 1 1 100%;
  }
}
