/**
* Modal styles
* Please use this file only to style modal-related properties
*/
.modal {
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  width: 100%;
  height: 100%;
  outline: 0;
  z-index: 1100;
  overflow-x: hidden;
  overflow-y: auto;
  &.d-block {
    display: block !important;
  }
  &__divider{
    margin-bottom: 10px !important;
  }
  &-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
  }

  /* Back layer */
  &-backdrop {
    z-index: 800;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    &.fade {
      opacity: 0;
    }
    &.show {
      opacity: 0.5;
    }
  }

  &-dialog {
    position: relative;
    width: auto;
    margin: 0.5rem;
    pointer-events: none;
    &-centered {
      display: flex;
      align-items: center;
      min-height: calc(100% - 1rem);
      &:before {
        display: block;
        height: calc(100vh - 1rem);
        content: "";
      }
    }
  }

  /* Inputs (only in modals) */
  .mat-form-field {
    width: 100%;
  }

  /* Hide inputs file to trigger with button  */
  input[type="file"] {
    display: none;
  }

  /* Icon buttons:  <button mat-icon-button> <mat-icon>delete</mat-icon> </button> */
  .mat-icon-button {
    margin-top: 14px;
    font-size: 20px;
  }

  /* Actual modal box */
  &-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    color: $modal-text-color;
    background: $modal-background-color;
    outline: 0;
    border-radius: 1px;
    border: 0;
  }

  /* Close button */
  &__close {
    background-image: url(/assets/img/close.svg);
    background-repeat: no-repeat;
    background-color: transparent;
    width: 14px;
    height: 14px;
    margin-top: 3px;
    padding: 7px;
    border: 0;
    box-sizing: border-box;
    transition: opacity 250ms ease;
    &:hover {
      opacity: 0.6;
    }
  }

  &__header {
    color: inherit;
    border: none;
    background: #23232F;
    display: flex;
    justify-content: space-between;
    padding: 30px;
    position: relative;
    &::after {
      content: '';
      position: absolute;
      left: 2%;
      bottom: 0;
      width: 95%;
      height: 1px;
      background-color: #33333E;
    }
  }

  &__body {
    color: inherit;
    padding: 15px;
    &--table {
      position: relative;
      padding-bottom: 36px;
    }
  }

  &__footer {
    color: inherit;
    border: none;
    text-align: right;
    padding: 15px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    button + button {
      margin-left: 6px;
    }
  }

  &__title {
    color: #DEDEE2;
    font-size: 18px;
    line-height: 1.1;
  }

  &__subtitle {
    color: inherit;
    font-size: 16px;
    line-height: 1.1;
    margin-top: 24px;
    margin-bottom: 12px;
  }

  /* Modal rows (vertical divisions) */
  &__row {
    margin-bottom: 10px;
    overflow: visible;
    &--fileslist {
      margin: 0;
      button.mat-icon-button {
        margin-top: 0;
      }
    }
    &--nomargin {
      margin: 0;
    }
    &--flex {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      > * {
        flex: 1;
        // flex: 1 1 auto;
        margin-right: 12px;
      }
      > .mat-form-field {
        width: auto;
      }
      .modal__minrow {
        flex: 0 0 auto;
      }
    }

    textarea{
      min-height: 100px;
    }
  }

  /* Modal labels */
  &__label {
    &-ms {
      font-size: 14px;
      &-opaque {
        opacity: 0.6;
      }

      i.warning {
        color: $modal-warning-color;
      }

      i.prohibited {
        color: $modal-prohibited-color;
      }
    }
  }
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
    &-centered {
      min-height: calc(100% - 3.5rem);
      &:before {
        height: calc(100vh - 3.5rem);
      }
    }
    &.modal-sm {
      max-width: 600px !important;
    }
    &.modal-lg {
      max-width: 900px !important;
    }
  }
}
