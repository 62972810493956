/**
* Document view styles
* Styles used to handle splited document pages
* Please use this file only to style document view properties
*/

/*
* The general approach is to add '.docview__info--fixed' and '.docview__picture--fixed'
* when the user scrolls beyond a certain position, which is determined by the page head's height.
*
* To add this behaviour, you can use a HostListener like:
*
* @HostListener('window:scroll', ['$event'])
* public onScroll(event) {
*   this.windowScrollTop = window.pageYOffset
* }
*
* public isDocumentRenderFixed() {
*   return this.windowScrollTop > this.distanceDocumentRenderToWindowTop;
* }
*
* And in the template:
*
* <div class="docview__info" [ngClass]="{'docview__info--fixed': isDocumentRenderFixed()}">
*   //...
* </div>
*/

.docview {
  &__wrap {
    display: flex;
    // flex-wrap: wrap;
    justify-content: center;
    // align-items: center;
  }

  /* left part, where information goes */
  &__info {
    // width: 100%;
    // flex: 1;
    order: 2;
    margin-top: 10px;
    height: 100%;
    // box-sizing: border-box;
  }

  /* right part, where document image goes */
  &__picture {
    // flex: 1 1 100%;
    order: 1;
    height: 100%;
    // box-sizing: border-box;
    // margin: 0 auto;
    overflow: scroll;
    width: 600px;
    max-width: 600px;
    &-app {
      height: 100%;
      display: block;
    }
  }
}

@media (min-width: 768px) {
  .docview {
    &__wrap {
      flex-wrap: nowrap;
    }

    &__info {
      flex: 0 0 50%;
      padding-right: 4px;
      order: 1;
      margin-top: 0;

      &--expanded {
        flex: 1 1 100%;
      }
      &--fixed {
        flex-grow: 0;
      }
      &--fullscreen {
        display: none;
      }
    }

    &__picture {
      flex: 0 0 50%;
      padding-left: 4px;
      max-height: calc(100vh - 145px);

      &--fixed {
        // position: fixed;
        z-index: 9;
        width: calc(50% - 55px);
        right: 10px;
        top: 46px;
        height: calc(100vh - 75px);
        max-height: initial;
      }
      &--fullscreen {
        position: fixed;
        z-index: 9;
        width: calc(100vw - 109px) !important;
        right: 10px;
        height: calc(100vh - 172px);
        max-height: initial;
      }
    }
  }
}

@media (min-width: 1100px) {
  .docview {
    $fields-table-fixed-width: 617px;
    &__info {
      width: $fields-table-fixed-width;
      flex: none;
      &--expanded {
        flex: 1 1 100%;
      }
    }

    &__picture {
      flex: none;
      width: calc(100% - #{$fields-table-fixed-width});
      &--fixed {
        width: calc(100vw - #{$fields-table-fixed-width} - 109px);
      }
    }
  }
}
