/* You can add global styles to this file, and also import other style files */
@use "@angular/material" as mat;
@include mat.core();

/* Importing Bootstrap SCSS file. */
@import "bootstrap/scss/bootstrap";

body {
  min-height: 90vh;
  font-size: 16px;
}

img {
  max-width: 100%;
}

a {
  color: #00885b;
  font-weight: 600;
  font-size: 15px;
  text-decoration: underline;
}

main {
  min-height: 90vh;
}

#wrapper {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  margin: 20px auto;
}

#main-content {
  width: 100%;
}

.padding-0 {
  padding: 0;
}

.border-box {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 15px;
}

.mar-btm {
  margin-bottom: 25px;
}

$theme-colors: (
  "primary": #ffe600,
  "secondary": #f7f7ff,
  // "success":#8a897c,
  "danger": #e03616,
  "dark": #333333,
  "warning": #ff9e43,
  "info": #8a897c,
);

/* Importing Bootstrap SCSS file. */
// @import "~bootstrap/scss/bootstrap";

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.main {
  // background-image: url('../../assets/img/background.png');
  // background-repeat: no-repeat;
  // background-size: cover;
  // background: linear-gradient(25deg, #242f5d 5%, #171f3e 99%);;
  // background: rgb(252, 236, 90);
  // background: linear-gradient(90deg, rgb(252, 236, 90) 0%, rgba(255,255,255,1) 30%);
}

.custom-navbar {
  background-color: #071344 !important;
  min-height: 5rem;
}

.custom-brand {
  color: #ffe600 !important;
}

.custom-navitem {
  color: #ffe600 !important;
}

.loading {
  opacity: 0.5;
  background-color: #484848;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 10vmin;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-modal {
  opacity: 0.5;
  background-color: #484848;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0vmin;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
}

.particles {
  position: absolute;
  width: 100%;
  height: auto;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

@font-face {
  font-family: ey;
  src: url(assets/fonts/EYInterstate-Regular.ttf);
}

@font-face {
  font-family: ey-light;
  src: url(assets/fonts/EYInterstate-Light.ttf);
}

@font-face {
  font-family: ey-bold;
  src: url(assets/fonts/EYInterstate-Bold.ttf);
}

* {
  font-family: "ey";
}

$primary: #ffe600;
$secondary: #f7f7ff;
$success: #8a897c;
$danger: #e03616;
$dark: #333333;

.btn-primary {
  @include button-variant(
    $primary,
    darken($primary, 7.5%),
    darken($primary, 10%),
    lighten($primary, 5%),
    lighten($primary, 10%),
    darken($primary, 30%)
  );
}
.btn-outline-primary {
  @include button-outline-variant(
    $primary,
    #222222,
    lighten($primary, 5%),
    $primary
  );
}
.btn-secondary {
  @include button-variant(
    $secondary,
    darken($secondary, 7.5%),
    darken($secondary, 10%),
    lighten($secondary, 5%),
    lighten($secondary, 10%),
    darken($secondary, 30%)
  );
}
.btn-outline-secondary {
  @include button-outline-variant(
    $secondary,
    #222222,
    lighten($secondary, 5%),
    $secondary
  );
}

.btn-success {
  @include button-variant(
    $success,
    darken($success, 7.5%),
    darken($success, 10%),
    lighten($success, 5%),
    lighten($success, 10%),
    darken($success, 30%)
  );
}
.btn-outline-success {
  @include button-outline-variant(
    $success,
    #222222,
    lighten($success, 5%),
    $success
  );
}

.btn-danger {
  @include button-variant(
    $danger,
    darken($danger, 7.5%),
    darken($danger, 10%),
    lighten($danger, 5%),
    lighten($danger, 10%),
    darken($danger, 30%)
  );
}
.btn-outline-danger {
  @include button-outline-variant(
    $danger,
    #222222,
    lighten($danger, 5%),
    $danger
  );
}

.btn-dark {
  @include button-variant(
    $dark,
    darken($dark, 7.5%),
    darken($dark, 10%),
    lighten($dark, 5%),
    lighten($dark, 10%),
    darken($dark, 30%)
  );
}
.btn-outline-dark {
  @include button-outline-variant($dark, #222222, lighten($dark, 5%), $dark);
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
