/**
* Page header styles
*/
.pagehead {
  background: $pagehead-background-color;
  .pagectrl {


    &__wrap {
      padding-left: 10px;
      display: flex;
      flex-wrap: wrap;
      overflow: hidden;
      margin-left: -10px;
    }

    app-kpi {
      flex: 1 1 100%;
    }

    app-breadcrumb {
      flex: 1 1 100%;
    }

    &__title {
      flex: 1 1 auto;
    }
  }
  &__tabs-block {
    margin-top: 10px;
    order: 999;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: calc(100% - 5px);
      height: 1px;
      background-color: #A4A3B1;
    }

    &--secondary {
      margin-top: 0px;
      margin-bottom: 10px;
      ul {
        padding-top: 5px;
        padding-left: 30px;
      }
    }

    &--secondary-tab {
      margin: -10px -10px 0px;
      ul {
        background-color: $pagehead-tab-list-secondary-background-color;
        padding-top: 5px;
        padding-left: 30px;
      }
    }
  }

  &__tabs-list {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__tab {
    display: inline-block;
    font-size: 14px;
    cursor: pointer;

    button {
      display: block;
      transition: color 200ms ease, background 200ms ease;
      background-color: transparent;
      color: #A4A3B1;
      padding: 5px 10px;
      border-radius: 0;
      border: 0;
      height: 40px;

      &:hover {
        color: #A4A3B1;
        background: #33333E;
      }
      @media (max-width: 1270px) {
        height: 27px;
      }
    }

    &.active button {
      color: #DEDEE2 !important;
      border-bottom: 3px solid #FFFFFF;
      &:hover {
        color: #FFFFFF !important;
        background-color: transparent;
      }
    }

    + .pagehead__tab {
      margin-left: 2px;
    }

    &--secondary {
      button {
        background-color: $pagehead-tab-secondary-background-color;
      }
      &.active button {
        background: $pagehead-tab-secondary-active !important;
      }
    }
  }

  &__buttons-block {
    flex: 1 1 100%;
    text-align: right;
    position: relative;
  }

  &__button {
    position: relative;
    display: inline-block;
    vertical-align: top;
    color: $pagehead-button-text-color;
    height: 25px;
    padding: 5px 10px;
    border: 0;
    cursor: pointer;
    background-color: $pagehead-button-background-color;
    background-repeat: no-repeat;
    background-position: center;
    outline: 0 !important;
    transition: background 200ms ease;
    box-sizing: content-box;
    min-width: 35px;
    font-size: 14px;
    border-radius: 4px;

    + .pagehead__button,
    + .pagehead__buttons-dropdown-wrap {
      margin-left: 2px;
    }

    &:not(.pagehead__button--disabled):hover {
      background-color: $pagehead-button-background-color-hover;
    }

    &:active {
      background-color: $pagehead-button-background-color-active;
    }

    &--disabled {
      opacity: 0.6;
      cursor: not-allowed !important;
    }

    &--new,
    &--update,
    &--send,
    &--download,
    &--consolidate-all,
    &--train,
    &--upload,
    &--record,
    &--validateProduct,
    &--remove,
    &--expand,
    &--filter,
    &--collapse {
      padding-left: 25px;

      &:before {
        font: normal normal normal 20px/1 FontAwesome;
        width: 15px;
        height: 15px;
        position: absolute;
        top: 8px;
        left: 5px;
        color: #A4A3B1;
      }
    }

    &--new {
      padding-left: 33px;
      &:before {
        content: '\f067';
        padding-left: 5px;
      }
    }
    &--expand:before {
      content: '\2304';
    }
    &--collapse:before {
      content: '\2303';
    }

    &--update:before {
      content: '\f021';
    }

    &--send:before {
      content: '\f1d8';
    }

    &--download {
      padding-left: 35px;
      &:before {
        content: '\f019';
        padding-left: 5px;
      }
    }

    &--consolidate-all:before {
      content: '\f0c5';
    }

    &--train:before {
      content: '\f013';
    }
    &--filter {
      padding-left: 30px;
      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 11px;
        left: 8px;
        width: 16px;
        height: 11px;
        background-image: url('/assets/img/icons/filter.svg');
        background-size: cover;
        background-repeat: no-repeat;
      }
    }

    &--upload:before {
      content: '\f093';
    }

    &--record:before {
      content: '\f130';
    }

    &--validateProduct {
      padding-left: 33px;
      &:before {
        content: '\f00c';
        padding-left: 5px;
      }
    }

    &--remove:before {
      content: '\f2ed';
      font-family: 'Font Awesome 5 Free';
      font-weight: 900;
    }
  }
  &__clear-button {
    color: #0F69AE;
    background: none;
    border: none;
    cursor: pointer;
    text-decoration: none;
    transition: background 200ms ease;
    &--disabled {
      color: #9897A6;
    }
    @media (max-width: 1270px) {
      font-size: 14px;
    }
  }
  &__apply-button {
    color: #FFF;
    background: none;
    border: none;
    cursor: pointer;
    text-decoration: none;
    transition: background 200ms ease;
    &--disabled {
      color: #9897A6;
    }
    @media (max-width: 1270px) {
      font-size: 14px;
    }
  }
  &__primary-button {
    font-size: 14px;
    color: #1A1A24;
    background-color: #FFFFFF;
    border-radius: 4px;
    border: none;
    padding: 0 20px 0 20px;
    transition: background 200ms ease;
    min-height: 35px;
    &:not(.pagehead__primary-button--disabled):hover {
      background-color: #DEDEE2;
    }
    &:active {
      background-color: #BFBFC8;
    }

    &--disabled {
      color: #737387;
      background-color: #2E2E38;
      cursor: not-allowed !important;
    }
  }
  &__secondary-button {
    font-size: 14px;
    color: #FFFFFF;
    background-color: transparent;
    border-radius: 4px;
    border: 1px solid #4E4E5D;
    padding: 0 20px 0 20px;
    transition: background 200ms ease;
    min-height: 35px;
    &:not(.pagehead__secondary-button--disabled):hover {
      background-color: #33333E;
    }
    &:active {
      background-color: #3F3F4C;
    }

    &--disabled {
      color: #737387;
      background-color: transparent;
      cursor: not-allowed !important;
    }
  }

  &__buttons-dropdown-wrap {
    display: inline-block;
    position: relative;

    &:hover {
      .pagehead__buttons-dropdown {
        display: block;
      }
    }
  }

  &__buttons-dropdown {
    position: absolute;
    padding-top: 2px;
    right: 0;
    display: none;
    z-index: 9;

    .pagehead__button {
      width: calc(100% - 20px);

      + .pagehead__button {
        margin-left: 0;
        margin-top: 2px;
      }
    }
  }
}

@media (min-width: 420px) {
  .pagehead {
    &__title {
      flex: 1 1 auto;
    }

    &__search {
      flex: 0 1 260px;
    }

    &__tabs-block {
      flex: 1 0 100%;
    }
  }
}

@media (min-width: 768px) {
  .pagehead {
    &__title {
      h1 {
        margin: 0;
        margin-left: 10px;
      }
    }
    &__buttons-block {
      flex: 1 1 auto;
    }
  }
}
