
 @mixin getSpeakerAttributes($index) {
  color: nth($speaker-colors, $index);
  }

  @mixin getBackgorundSpeakers($index) {
    border: 3px solid nth($speaker-colors, $index);
    color: nth($speaker-colors, $index);
  }

 .speaker{

  &__speaker-simple{
    font-size: 12px;
    &--1{
      @include getSpeakerAttributes(1);
    }
    &--2{
      @include getSpeakerAttributes(2);
    }
    &--3{
      @include getSpeakerAttributes(3);
    }
    &--4{
      @include getSpeakerAttributes(4);
    }
    &--5{
      @include getSpeakerAttributes(5);
    }
    &--6{
      @include getSpeakerAttributes(6);
    }
    &--7{
      @include getSpeakerAttributes(7);
    }
    &--8{
      @include getSpeakerAttributes(8);
    }
    &--9{
      @include getSpeakerAttributes(9);
    }
    &--10{
      @include getSpeakerAttributes(10);
    }
  }

  &__speaker-background{
    font-size: 12px;
    font-weight: bold;
    &--1{
      @include getBackgorundSpeakers(1);
    }
    &--2{
      @include getBackgorundSpeakers(2);
    }
    &--3{
      @include getBackgorundSpeakers(3);
    }
    &--4{
      @include getBackgorundSpeakers(4);
    }
    &--5{
      @include getBackgorundSpeakers(5);
    }
    &--6{
      @include getBackgorundSpeakers(6);
    }
    &--7{
      @include getBackgorundSpeakers(7);
    }
    &--8{
      @include getBackgorundSpeakers(8);
    }
    &--9{
      @include getBackgorundSpeakers(9);
    }
    &--10{
      @include getBackgorundSpeakers(10);
    }
  }



 }
