.languagemodel-phrases{
  &__intent-title{
    width: 23%;
    text-align: center;
  }

  &__text-title{
    width: 45%;
    padding: 5px 15px;
  }
}

.languagemodel-phrase{
  &__body{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: 0;
    border-radius: 2px;
    padding: 3px 5px;
    line-height: 2;
    color: $table-text-color;
    border-left: 1.5px solid rgba(0, 0, 0, 0.3);
    border-right: 1.5px solid rgba(0, 0, 0, 0.3);
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    background: transparent;
    min-height: 75px;
    box-sizing: border-box;
    font-weight: normal;
    outline: 1px solid transparent;

    &--first{
      border-top: 1px solid rgba(0, 0, 0, 0.3);
    }
  }



  &__text{
    width: 45%;
    font-size: 12px;
    padding: 5px 10px;
    text-align: justify;

    textarea{
      background-color: transparent;
      width: 98%;
      color: $table-text-color;
      text-align: justify;
      line-height: 24px;
    }

    &--dictionary{
      width: 75%;
    }

  }

  &__text-edit{
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__text-buttons{
    display: flex;
    flex-direction: column;
    margin-left: 3px;

    button {
      border: 0;
      padding: 0 0 0 3px;
      background: transparent;
      transition: opacity 150ms ease;
      margin: 2px 0px;
      &:before {
        font: normal normal normal 13px/1 FontAwesome;
        color: inherit;
      }
      &:not(.voicemodel-phrase__text-buttons--disabled):hover {
        color: $table-row-hover-outline-color;
      }
    }

    &--accept{
      color: $completed-color;
    &:before{
      content: "\f00c";
      }
    }

    &--cancel{
      color: $danger-color;
      &:before{
        content: "\f00d";
      }
    }

    &--undo{
      color: $table-text-color;
      &:before{
        content: "\f0e2";
      }
    }

    &--disabled{
      opacity: 0.6;
      cursor: not-allowed !important;
      color: inherit;
    }
  }

  &__intent{
    padding: 5px 10px 5px 5px;
  }

  &__buttons{
    button {
      border: 0;
      padding: 0 0 0 3px;
      background: transparent;
      transition: opacity 250ms ease;
      color: $table-text-color;
      margin: 0px 10px;
      &:before {
        font: normal normal normal 16px/1 FontAwesome;
        color: inherit;
      }
      &:not(.voicemodel-phrase__buttons--disabled):hover {
        color: $table-row-hover-outline-color;
      }
    }

    &--edit:before{
      content: "\f044";
    }

    &--train:before{
      content: "\f067";
    }

    &--discard:before{
      content: "\f1f8";
    }

    &--add:before{
      content: "\f00c";
    }

    &--disabled{
      opacity: 0.6;
      cursor: not-allowed !important;
      color: inherit;
    }

    &--dictionary{
      display: flex;
      justify-content: center;
      min-width: 12%;
    }
  }

  &__spinner{
    width: 12%;
  }
}
