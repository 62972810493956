/**
* Fonts imports and styles
* Please use this file only to style font-related properties
*/

@font-face {
	font-family: 'Notosans-Regular';
	src: url('/assets/fonts/NotoSans-Regular.eot');
	src: local('NotoSans'),
		url('/assets/fonts/NotoSans-Regular.eot?#iefix')
			format('embedded-opentype'),
		url('/assets/fonts/NotoSans-Regular.woff') format('woff'),
		url('/assets/fonts/NotoSans-Regular.ttf') format('truetype');
}

@font-face {
	font-family: 'NotoSans-Bold';
	src: url('/assets/fonts/NotoSans-Bold.eot');
	src: local('NotoSans'),
		url('/assets/fonts/NotoSans-Bold.eot?#iefix')
			format('embedded-opentype'),
		url('/assets/fonts/NotoSans-Bold.woff') format('woff'),
		url('/assets/fonts/NotoSans-Bold.ttf') format('truetype');
}

body, h1, h2, h3, h4, h5, h6, p, ul, ol, li, blockquote, caption, cite, table {
	font-family: 'Notosans-Regular', Helvetica, Arial, sans-serif;
}