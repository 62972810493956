/**
* Status label styles
* Please use this file only to style document status cells on tables
*/

/* Status cell */
.text-status {
  position: relative;
  padding-left: 12px;
  white-space: pre;

  /* Status cell prepended circle */
  &:before {
    content: '';
    width: 10px;
    height: 10px;
    background: grey; /* unset color */
    border-radius: 10px;
    position: absolute;
    top: 3px;
    left: 0;
  }

  &--not-started{
    color: $not-started-color;
    &:before {background-color: $not-started-color;}
  }

  &--started{
    color: $started-color;
    &:before {background-color: $started-color;}
  }

  &--training{
    color: $training-color;
    &:before {background-color: $training-color;}
  }

  &--completed{
    color: $completed-color;
    &:before {background-color: $completed-color;}
  }

  &--transcribing{
    color: $transcribing-color;
    &:before {background-color: $transcribing-color;}
  }

  &--processing{
    color: $processing-color;
    &:before {background-color: $processing-color;}
  }

  &--analyzing{
    color: $analyzing-color;
    &:before {background-color: $analyzing-color;}
  }

  &--failed{
    color: $failed-color;
    &:before {background-color: $failed-color;}
  }

  &--success {
    color: $success-color;
    &:before {background-color: $success-color;}
  }

  &--warning {
    color: $warning-color;
    &:before {background-color: $warning-color;}
  }

  &--failure, &--extraction-failure, &--br-failure, &--preliminary-failure, &--catalog-failure {
    color: $danger-color;
    &:before {background-color: $danger-color;}
  }

  &--in-progress, &--extraction-in-progress, &--br-in-progress, &--preliminary-in-progress, &--catalog-in-progress {
    color: $info-color;
    &:before {background-color: $info-color;}
  }

  &--pending, &--extraction-pending, &--br-pending, &--preliminary-pending, &--catalog-pending {
    color: $nostatus-color;
    &:before {background-color: $nostatus-color;}
  }

  &--partial-success {
    color: $partial-success-color;
    &:before {background-color: $partial-success-color;}
  }
}

/* Status cell without circle */
.text-noball-status {
  border-radius: 5px;
  margin-left: 10px;
  padding: 0px 5px;
  position: relative;
  color: $body-text-color;

  &--not-started{
    background-color: $not-started-color;
    &:before {background-color: $not-started-color;}
  }

  &--started{
    background-color: $started-color;
    &:before {background-color: $started-color;}
  }

  &--training{
    background-color: $training-color;
    &:before {background-color: $training-color;}
  }

  &--completed{
    background-color: $completed-color;
    &:before {background-color: $completed-color;}
  }

  &--transcribing{
    background-color: $transcribing-color;
    &:before {background-color: $transcribing-color;}
  }

  &--processing{
    background-color: $processing-color;
    &:before {background-color: $processing-color;}
  }

  &--analyzing{
    background-color: $analyzing-color;
    &:before {background-color: $analyzing-color;}
  }

  &--failed{
    background-color: $failed-color;
    &:before {background-color: $failed-color;}
  }

  &--success {
    background-color: $success-color;
    &:before {background-color: $success-color;}
  }

  &--warning {
    background-color: $warning-color;
    &:before {background-color: $warning-color;}
  }

  &--failure, &--extraction-failure, &--br-failure, &--preliminary-failure, &--catalog-failure {
    background-color: $danger-color;
    &:before {background-color: $danger-color;}
  }

  &--in-progress, &--extraction-in-progress, &--br-in-progress, &--preliminary-in-progress, &--catalog-in-progress {
    background-color: $info-color;
    &:before {background-color: $info-color;}
  }

  &--pending, &--extraction-pending, &--br-pending, &--preliminary-pending, &--catalog-pending {
    background-color: $nostatus-color;
    &:before {background-color: $nostatus-color;}
  }

  &--partial-success {
    background-color: $partial-success-color;
    &:before {background-color: $partial-success-color;}
  }
}

/* Status border */
.status-border{
  border-bottom: 1px solid;
  padding: 5px;
  margin-bottom: 10px;

  &--warning {
    border-color: $warning-color!important;
  }

  &--success {
    border-color: $success-color!important;
  }

  &--failure {
    border-color: $danger-color!important;
  }

  &--in-progress {
    border-color: $info-color!important;
  }

  &--pending {
    border-color: $nostatus-color!important;
  }

  &--partial-success {
    border-color: $partial-success-color!important;
  }
}

